<template>
  <v-app>
    <v-app-bar app color="white">
      <div class="d-flex align-center">
        <v-img alt="Logo" class="shrink mr-2" contain src="../src/assets/addrea_logo.svg" transition="scale-transition" />
      </div>
      <v-toolbar-title>СЛУЖБА ТЕХНИЧЕСКОЙ ПОДДЕРЖКИ ЭДДРЕА</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title><a class="link" href="mailto:support@addrea.com">support@addrea.com</a></v-toolbar-title>
      <v-toolbar-title class="pl-5"><a class="link" href="tel:+78005507093">+7 (800) 550-70-93 доб.
          2</a></v-toolbar-title>
    </v-app-bar>
    <v-main>
      <Form class="pt-5" />
    </v-main>
  </v-app>
</template>

<script>
import Form from './components/Form'

export default {
  name: 'App',

  components: {
    Form,
  },

  data: () => ({
    //
  }),
}
</script>

<style>
.link {
  text-decoration: none;
  font-size: 0.8em;
}

.v-application a {
  color: gray !important;
}
</style>