import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VAppBar,{attrs:{"app":"","color":"white"}},[_c('div',{staticClass:"d-flex align-center"},[_c(VImg,{staticClass:"shrink mr-2",attrs:{"alt":"Logo","contain":"","src":require("../src/assets/addrea_logo.svg"),"transition":"scale-transition"}})],1),_c(VToolbarTitle,[_vm._v("СЛУЖБА ТЕХНИЧЕСКОЙ ПОДДЕРЖКИ ЭДДРЕА")]),_c(VSpacer),_c(VToolbarTitle,[_c('a',{staticClass:"link",attrs:{"href":"mailto:support@addrea.com"}},[_vm._v("support@addrea.com")])]),_c(VToolbarTitle,{staticClass:"pl-5"},[_c('a',{staticClass:"link",attrs:{"href":"tel:+78005507093"}},[_vm._v("+7 (800) 550-70-93 доб. 2")])])],1),_c(VMain,[_c('Form',{staticClass:"pt-5"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }