<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="300">

        <v-card>
          <v-card-title class="text-h5">
            Заявка создана
          </v-card-title>
          <v-card-text>Ваше обращение отправлено!</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="closeDialog">
              Закрыть
            </v-btn>
          </v-card-actions>
        </v-card>

      </v-dialog>
    </v-row>
    <v-row class="text-center pt-5">
      <v-col cols="12">
        <v-card class="mx-auto p-5" max-width="1000" outlined elevation="7">
          <v-container class="mt-5">
            <v-form ref="form" lazy-validation>
              <v-text-field prepend-icon="mdi-domain" v-model="form.company" label="Компания" color="purple" outlined
                dense />
              <v-text-field prepend-icon="mdi-city" v-model="form.city" label="Город" color="purple" outlined dense />
              <v-text-field prepend-icon="mdi-rename-outline" v-model="form.nameObject"
                label="Название магазина или объекта" color="purple" outlined dense />
              <v-text-field prepend-icon="mdi-map-marker-outline" v-model="form.addressObject"
                label="Адрес магазина или объекта" color="purple" outlined dense />
              <v-text-field prepend-icon="mdi-account-box-outline" v-model="form.contact" label="Контактное лицо (ФИО)"
                color="purple" outlined dense />
              <v-text-field prepend-icon="mdi-phone-outline" v-model="form.tel" label="Телефон" color="purple" outlined
                dense />
              <v-text-field prepend-icon="mdi-email-outline" v-model="form.email" label="E-mail" color="purple" outlined
                dense />
              <v-text-field prepend-icon="mdi-text" v-model="form.description" label="Текст заявки" color="purple"
                outlined dense />
              <v-file-input class="pb-5" v-model="form.file" label="Прикрепить файл"
                accept="image/png, image/gif, image/jpeg" color="purple" outlined dense />
            </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined color="purple" @click="createTaskBitrix">
                Отправить
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
export default {
  name: 'FormComp',

  data: () => ({
    TOKEN: '5633389297:AAFX2axCrR_PW0cYpXBatZOym3erkfsdVMo',
    CHATID: '-936090854',
    dialog: false,
    form: {
      company: '',
      city: '',
      nameObject: '',
      addressObject: '',
      contact: '',
      tel: '',
      email: '',
      description: '',
      file: null
    }
  }),
  methods: {
    createTaskBitrix () {
      //this.sendAllDocs()
      axios({
        method: 'post',
        url: 'https://erp.addrea.com/rest/118/6cvp5ogz1l0olxc0/tasks.task.add',
        params: {},
        data: {
          fields: {
            //UF_CRM_TASK: ['CO_119'],
            TITLE: this.form.city + '. ' + this.form.company + ' - ' + this.form.description,
            DESCRIPTION:
              'Компания: ' + this.form.company + '<br>' +
              'Город: ' + this.form.city + '<br>' +
              'Объект ' + this.form.nameObject + '<br>' +
              'Контактное лицо: ' + this.form.contact + '<br>' +
              'Адрес объекта: ' + this.form.addressObject + '<br>' +
              'Телефон: ' + this.form.tel + '<br>' +
              'E-mail: ' + this.form.email + '<br>' +
              'Текст заявки: ' + this.form.description,
            //UF_CRM_1584547279018: this.deal,
            GROUP_ID: 15,
            CREATED_BY: 53, //erp user
            RESPONSIBLE_ID: 48,
            UF_TASK_WEBDAV_FILES: this.idsUploadFiles,
            // DEADLINE: this.date2,
            // UF_TASK_TYPE: 567, // Тип задачи
            // UF_TASK_SUBTYPES: 5802, //Тип подзадачи
          },
        },
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
        .then((response) => {
          console.log(response.data.result.task.id)
          this.onSubmit()
          //console.log('Задача с номером: ', this.taskId, 'cоздана!')
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    onSubmit () {
      axios.post('https://api.telegram.org/bot' + this.TOKEN + '/sendMessage?chat_id=' + this.CHATID + "&text=<strong>Новая заявка ТП</strong>" + "%0A" + "%0A" +
        "Компания: " + this.form.company + "%0A" +
        "Город: " + this.form.city + "%0A" +
        "Название магазина или объекта: " + this.form.nameObject + "%0A" +
        "Адрес магазина или объекта: " + this.form.addressObject + "%0A" +
        "Контактное лицо: " + this.form.contact + "%0A" +
        "Телефон: " + this.form.tel + "%0A" +
        "E-mail: " + this.form.email + "%0A" +
        "Текст заявки: " + this.form.description, "&parse_mode=HTML"
      )
        .then(response => (response.data))
        .then(this.onSubmitPhoto)
        .then(this.dialog = !this.dialog)
        .catch(function (error) {
          console.log(error)
        })

    },
    async onSubmitPhoto () {
      console.log('вызывается метод')
      if (this.form.file != null) {
        console.log('вызывается метод внутри иф')
        var blob = new Blob([this.form.file], { type: 'plain/text' })
        var formData = new FormData()
        formData.append('chat_id', this.CHATID)
        formData.append('document', blob, 'file.jpg')

        var request = new XMLHttpRequest()
        request.open('POST', `https://api.telegram.org/bot${this.TOKEN}/sendDocument`)
        request.send(formData)
      }
    },
    closeDialog () {
      window.location.reload()
      this.dialog = false
    }
  },
}
</script>
